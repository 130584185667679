<template>
  <div id="cancelPending">
    <div class="goBack">
      <i class="goBackClose" @click="jumpBack"></i>
    </div>
    <div class="pendingTitle">订单取消挂起</div>
    <div class="reasonInstructions">
      <div class="reasonTitle">取消挂起原因说明<span>*</span></div>
      <textarea v-model="inputInstructions" class="inputInstructions" maxlength="100" placeholder="请输入取消挂起原因，最多100字"></textarea>
    </div>
    <div class="uploadPictures">
      <div class="uploadTitle">上传图片</div>
      <div class="uploadBox">
        <ul>
          <li class="uploadImg" v-for="(imgs, index) in exImgs" :key="index">
            <img class="deleteImg" src="../assets/images/pending/delete.png" alt="" @click="deleteImg(index)" />
            <img class="showImg" :src="imgs" alt="" @click="bigImg(exImgs, index)" />
          </li>
          <li class="addButton" @click="isClickUpload" v-show="isShow">
            <img src="../assets/images/pending/addImage.png" alt="" />
          </li>
        </ul>
      </div>
    </div>
    <div class="serviceTime">
      <div class="title">反馈上门服务时间<span>*</span></div>
      <div class="tip">
        请与客户详细沟通确认后，再变更二次上门服务时间，平台将根据您反馈的时间为您购买人身意外伤害保险。若未按约定时间上门导致事故发生需您自行承担责任。
      </div>
      <div>
        <van-cell class="cell" :border="false" :value="departTime" title="预计出发时间" @click="departTimeShow = true">
          <template #right-icon>
            <van-icon name="arrow-down" class="arrow-down" />
          </template>
        </van-cell>
        <div class="hairline"></div>
        <van-cell class="cell" :border="false" :value="arriveTime" title="预计到达时间" @click="arriveTimeShow = true">
          <template #right-icon>
            <van-icon name="arrow-down" class="arrow-down" />
          </template>
        </van-cell>
        <div class="hairline"></div>
        <div class="cellGroup">
          <van-cell class="cell" :border="false" :value="finishedTime" title="预计完工时间" @click="finishedTimeShow = true">
            <template #right-icon>
              <van-icon name="arrow-down" class="arrow-down" />
            </template>
          </van-cell>
          <div class="right" @click="finishedAddDay">平均{{ recommendDay }}天完成</div>
        </div>
        <div class="hairline"></div>
      </div>
      <div class="insTip" v-if="insTip">计划于{{ departTime }} - {{ finishedTime }} 购买意外伤害保险</div>
    </div>
    <!-- <div class="sweetTip">
      <div class="title">说明:</div>
      <p>1. 订单解除挂起，订单进程和工程师的保险将立即恢复执行。</p>
    </div> -->
    <div class="pendingConfirm" @click="confirmUp">确认提交</div>
    <van-overlay :show="show">
      <van-loading size="24px" vertical>上传中...</van-loading>
    </van-overlay>
    <van-popup v-model="departTimeShow" round position="bottom">
      <van-datetime-picker
        type="datetime"
        title="请选择出发时间"
        :min-date="minDate"
        :filter="filter"
        @cancel="departTimeShow = false"
        @confirm="changeDepart"
      />
    </van-popup>
    <van-popup v-model="arriveTimeShow" round position="bottom">
      <van-datetime-picker
        type="datetime"
        title="请选择到达时间"
        :min-date="minDate"
        :filter="filter"
        @cancel="arriveTimeShow = false"
        @confirm="changeArrive"
      />
    </van-popup>
    <van-popup v-model="finishedTimeShow" round position="bottom">
      <van-datetime-picker
        type="datetime"
        title="请选择完工时间"
        :min-date="minDate"
        :max-date="maxDate"
        :filter="filter"
        @cancel="finishedTimeShow = false"
        @confirm="changeFinished"
      />
    </van-popup>
  </div>
</template>

<script>
import { dialog, Cell, CellGroup, DatetimePicker, Icon } from "../utils/vant.js";
import { ImagePreview } from "vant";
import { handleOrderHangEx, getOrderInsDate } from "../api/home";
import { getCalls } from "../utils/upload";
export default {
  data() {
    return {
      userId: this.$route.query.userId, //用户id
      orderNo: this.$route.query.orderNo, //订单id
      inputInstructions: "", //取消挂起原因
      isShow: true,
      exImgs: [],
      show: false, //上传加载显示
      departTime: "请选择出发时间", //出发时间
      arriveTime: "请选择到达时间", //到达时间
      finishedTime: "请选择完工时间", //完工时间
      departTimeShow: false, //出发时间显示
      arriveTimeShow: false, //到达时间显示
      finishedTimeShow: false, //完工时间显示
      minDate: new Date(),
      maxDate: new Date(2025, 12, 30),
      recommendDay: 0, //推荐天数
      limitDay: 0, //上线天数
      insTip: false, //计划保险提示
    };
  },
  mounted() {
    window.getUpdateImageUrl_call = (res) => {
      this.getUpdateImageUrl_call(res);
    };
    this.getOrderInsDate();
  },
  methods: {
    //获取推荐天数
    getOrderInsDate() {
      let obj = {
        orderNo: this.orderNo,
        userId: this.userId,
      };
      getOrderInsDate(obj).then((res) => {
        this.recommendDay = res.result[0].recommendDay;
        this.limitDay = res.result[0].limitDay;
      });
    },
    //过滤分钟显示
    filter(type, options) {
      if (type === "minute") {
        return options.filter((option) => option % 5 === 0);
      }
      return options;
    },
    //出发时间选择
    changeDepart(value) {
      let time = this.getDate(value);
      this.departTime = time;
      this.departTimeShow = false;
      let dateTime = new Date(this.departTime.replace(new RegExp(/-/gm), "/")).getTime();
      let Day = this.getTargetDate(dateTime, this.limitDay);
      let arrDay = Day.split(" ")[0].split("-");
      this.maxDate = new Date(arrDay[0], `0${arrDay[1] - 1}`, arrDay[2]);
      this.finishedTime = "请选择完工时间";
      this.insTip = false;
    },
    //到达时间选择
    changeArrive(value) {
      let time = this.getDate(value);
      this.arriveTime = time;
      this.arriveTimeShow = false;
    },
    //完工时间选择
    changeFinished(value) {
      if (this.departTime == "请选择出发时间") {
        this.$dialog.alert({
          message: "请先选择出发时间!",
        });
        return;
      }
      let time = this.getDate(value);
      this.finishedTime = time;
      this.finishedTimeShow = false;
      this.insTip = true;
    },
    finishedAddDay() {
      if (this.departTime == "请选择出发时间") {
        this.$dialog.alert({
          message: "请先选择出发时间!",
        });
        return;
      }
      let dateTime = new Date(this.departTime.replace(new RegExp(/-/gm), "/")).getTime();
      let time = this.getTargetDate(dateTime, this.recommendDay);
      this.finishedTime = time;
      this.insTip = true;
    },
    //获取当前年月日加对应的天数
    getTargetDate(startDate, days) {
      var startTime = startDate;
      var diff = days * 86400 * 1000;
      var endTime = startTime + diff;
      var d = new Date(endTime);
      var CurrentDate = "";
      CurrentDate += d.getFullYear();
      if (d.getMonth() + 1 > 9) {
        CurrentDate += "-" + (d.getMonth() + 1);
      } else {
        CurrentDate += "-0" + (d.getMonth() + 1);
      }
      if (d.getDate() > 9) {
        CurrentDate += "-" + d.getDate();
      } else {
        CurrentDate += "-0" + d.getDate();
      }
      return CurrentDate + " " + this.departTime.split(" ")[1];
    },
    //时间转换年月日时分
    getDate(time) {
      var year = time.getFullYear();
      var month = time.getMonth() + 1 < 10 ? "0" + (time.getMonth() + 1) : time.getMonth() + 1;
      var day = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
      var hour = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
      var minute = time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
      return year + "-" + month + "-" + day + " " + hour + ":" + minute;
    },
    //点击图片上传调用原生方法
    isClickUpload() {
      getCalls(this.exImgs);
    },
    //图片上传后的回调
    getUpdateImageUrl_call(res) {
      let imgs = JSON.parse(res);
      imgs.map((e) => {
        this.exImgs.push(e.filePath);
      });
      if (this.exImgs.length >= 10) {
        this.isShow = false;
      }
    },
    //图片预览
    bigImg(imgs, index) {
      ImagePreview({
        images: imgs, // 预览图片的那个数组
        showIndex: true,
        startPosition: index, // 指明预览第几张图
      });
    },
    //删除图片
    deleteImg(index) {
      this.exImgs.splice(index, 1);
      this.isShow = true;
    },
    // 取消挂起确认提交
    confirmUp() {
      let _this = this;
      if (_this.inputInstructions == "") {
        _this.$dialog.alert({
          message: "取消挂起原因不能为空！",
        });
        return;
      }
      if (this.departTime == "请选择出发时间") {
        _this.$dialog.alert({
          message: "请选择出发时间!",
        });
        return;
      }
      if (this.arriveTime == "请选择到达时间") {
        _this.$dialog.alert({
          message: "请选择到达时间!",
        });
        return;
      }
      if (this.finishedTime == "请选择完工时间") {
        _this.$dialog.alert({
          message: "请选择完工时间!",
        });
        return;
      }
      if (new Date(this.departTime).getTime() >= new Date(this.arriveTime).getTime()) {
        _this.$dialog.alert({
          message: "到达时间必须大于出发时间!",
        });
        return;
      }
      if (new Date(this.arriveTime).getTime() >= new Date(this.finishedTime).getTime()) {
        _this.$dialog.alert({
          message: "完工时间必须大于到达时间!",
        });
        return;
      }
      this.show = true;
      let params = {
        userId: _this.userId,
        orderNo: _this.orderNo,
        exHandleDesc: _this.inputInstructions,
        exHanleImgs: _this.exImgs + "",
        expectDepartDate: `${_this.departTime}:00`,
        expectArrivalTime: `${_this.arriveTime}:00`,
        expectFinishTime: `${_this.finishedTime}:00`,
      };
      handleOrderHangEx(params).then((res) => {
        if (res.status) {
          this.show = false;
          _this.$dialog
            .alert({
              message: "订单挂起已取消！",
            })
            .then(() => {
              window.history.go(-1);
            });
        } else {
          this.show = false;
          _this.$dialog.alert({
            message: "取消挂起异常，请稍后再试！",
          });
        }
      });
    },
    // 跳转返回
    jumpBack() {
      window.history.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
#cancelPending {
  text-align: left;

  .goBack {
    width: 750px;
    height: 76px;
    background: #ffffff;

    .goBackClose {
      display: inline-block;
      width: 31px;
      height: 31px;
      margin: 23px 0 0 25px;
      background: url("../assets/images/pending/goBackClose.png") no-repeat;
      background-size: 100%;
      cursor: pointer;
    }
  }

  .pendingTitle {
    font-size: 54px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #111111;
    line-height: 54px;
    margin: 25px 0 0 29px;
  }

  .reasonInstructions {
    margin: 50px 0 0 29px;

    .reasonTitle {
      height: 24px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #111111;
      line-height: 30px;

      span {
        font-size: 26px;
        color: #ff7000;
        margin-left: 4px;
        vertical-align: middle;
      }
    }

    .inputInstructions {
      width: 690px;
      height: 248px;
      padding: 20px;
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 7px;
      margin-top: 30px;
      resize: none;
      outline: none;
      box-sizing: border-box;
      font-size: 26px;
    }
  }

  .uploadPictures {
    margin: 49px 0 0 29px;

    .uploadTitle {
      height: 24px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #111111;
      line-height: 30px;
    }

    .uploadBox {
      width: 690px;
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 7px;
      margin-top: 31px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 110px;
          height: 110px;
          margin: 10px 14px;
        }
        .uploadImg {
          position: relative;
          .showImg {
            width: 100%;
            height: 100%;
          }
          .deleteImg {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 32px;
            height: 32px;
          }
        }
        .addButton {
          background: #f5f5f5;
          display: flex;
          img {
            margin: auto;
            width: 37px;
            height: 59px;
          }
        }
      }
    }
  }

  .serviceTime {
    margin: 49px 0 0 0;

    .title {
      font-size: 24px;
      color: #111111;
      line-height: 30px;
      margin-left: 29px;

      span {
        font-size: 26px;
        color: #ff7000;
        margin-left: 4px;
        vertical-align: middle;
      }
    }

    .tip {
      width: 690px;
      font-size: 26px;
      color: #ff7000;
      line-height: 30px;
      margin-left: 29px;
    }

    .hairline {
      width: 690px;
      height: 1px;
      background: #f2f3f5;
      margin: 2px auto;
    }

    .arrow-down {
      margin-top: 10px;
      margin-left: 5px;
    }

    .cell {
      width: 75%;
      margin-top: 2px;
    }

    .cellGroup {
      position: relative;

      .right {
        position: absolute;
        top: 50%;
        right: 30px;
        font-size: 24px;
        transform: translate(0, -50%);
        color: red;
      }
    }

    .insTip {
      margin-left: 29px;
      margin-top: 10px;
      color: red;
      font-size: 26px;
    }
  }

  .sweetTip {
    margin: 49px 0 0 29px;
    color: #999999;

    .title {
      font-size: 26px;
    }

    p {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 36px;
    }
  }

  .pendingConfirm {
    width: 690px;
    height: 80px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #fafafa;
    text-align: center;
    line-height: 80px;
    background: #ff7000;
    border-radius: 8px;
    margin: 60px auto;
    cursor: pointer;
  }
}
/deep/.van-loading {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
